import "../App.css";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import ConfirmationModal from "./reusable/ConfirmationModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import APIRequest from "../utils/APIRequest";
import ReactPaginate from "react-paginate";

const newSocketUrls = [
  "https://socket-multi-dev.komunestudio.com",
  // "https://multi2.danamonlearningfest.id",
  // "https://multi3.danamonlearningfest.id",
  // "https://multi4.danamonlearningfest.id",
  // "https://multi5.danamonlearningfest.id",
  // "https://multi6.danamonlearningfest.id",
  // "https://multi7.danamonlearningfest.id",
  // "https://multi8.danamonlearningfest.id",
  // "https://multi9.danamonlearningfest.id",
  // "https://multi10.danamonlearningfest.id",
  // "https://multi11.danamonlearningfest.id",
  // "https://multi12.danamonlearningfest.id",
  // "https://multi13.danamonlearningfest.id",
  // "https://multi14.danamonlearningfest.id",
  // "https://multi15.danamonlearningfest.id",
  // "https://multi16.danamonlearningfest.id",
  // "https://multi17.danamonlearningfest.id",
  // "https://multi18.danamonlearningfest.id",
  // "https://multi19.danamonlearningfest.id",
  // "https://multi20.danamonlearningfest.id",
];


// const rooms = [
//   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
// ];

const ITEMS_PER_PAGE = 6;

const defaultMetas = [
  null, null, null, null, null, null,
  null, null, null, null, null, null,
  null, null, null, null, null, null,
  null, null, null, null,
]

export default function RoomSelectionPage() {
  let navigate = useNavigate();

  const lang = localStorage.getItem("lang");
  const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);
  const [room, setRoom] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [metas, setMetas] = useState([...defaultMetas]);

  const getMetas = async () => {
    let params = [null/* , null, null, null, null, null */];

    for(let i in newSocketUrls){
      params = await getMeta(i, params);
      console.log("OTW " + i);
    }


  };

  const getMeta = async (index, params) => {
    try {

      console.log("getting metas", newSocketUrls[index])

      let result = await APIRequest.setCustom(newSocketUrls[index], "meta", "GET");

      if (result.connected || result.connected === 0) {
        let temp = params ? params : [...metas];
        temp[index] = result.connected;

        if (result.connected > 50) {
          temp[index] = 50;
        }

        console.log("SETTINGOTW", temp);

        setMetas([...temp]);
        return temp;
      }

      if (params) {
        return params;
      } else {
        return [...defaultMetas];
      }
    } catch (e) {
      console.log(e);
      if (params) {
        return params;
      } else {
        return [...defaultMetas];
      }
    }
  };

  useEffect(() => {
    getMetas();
  }, []);

  useEffect(() => {
    function handleResize() {
      console.log(window.innerWidth);

      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    console.log(localStorage)

    if (
      !localStorage.getItem("token") ||
      !localStorage.getItem("character") ||
      !localStorage.getItem("gender") ||
      !localStorage.getItem("username") ||
      !localStorage.getItem("lang")
    ) {
      navigate("/");
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newSocketUrls.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newSocketUrls.length / ITEMS_PER_PAGE));
  }, [itemOffset, ITEMS_PER_PAGE]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 6) % newSocketUrls.length;
    setCurrentPage(event.selected);
    console.log(
      `currentPage ${event.selected}, pageCount ${pageCount}`
    );
    setItemOffset(newOffset);
  };

  const renderOldPage = () =>{
    return <Row>{
      currentItems?.map((r, key) => (
        <Col
          xs={6}
          md={4}
          style={{
            backgroundImage:
              room === ((6 * currentPage) + key) ? 'url("Group 28.png")' : 'url("Group 25.png")',
            color: room === ((6 * currentPage) + key) ? "#EFECDB" : "white",
            pointerEvents: r >= metas.length && "none",
            backgroundSize: "100% 100%",
          }}
        >
          <div
            onClick={() => setRoom((6 * currentPage) + key)}
            className="roomCard w-100 position-relative"
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "1rem",
                lineHeight: 1,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: 0,
              }}
              className="position-absolute pb-2"
            >
              {lang === "EN" ? "Room " : "Ruangan "}
              {(6 * currentPage) + key + 1}
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "LeelaUIb",
                }}
              >
                {metas[6 * currentPage + key] != null ? (
                  ` (${metas[6 * currentPage + key]}/50)`
                ) : (
                  <div style={{color: "red"}}>Offline</div>
                )}
              </div>
            </div>
          </div>
        </Col>
      ))
    }
    {
      currentPage === pageCount - 1 && [0, 1, 2, 3].map((t) => ( //temporary solution supaya layout gk shift
        <Col
          xs={6}
          md={4}
          style={{
            backgroundImage: 'url("Group 28.png")',
            color: "#EFECDB",
            pointerEvents: "none",
            backgroundSize: "100% 100%",
            opacity: 0
          }}
        >
          <div
            className="roomCard w-100 position-relative"
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "1rem",
                lineHeight: 1,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: 0,
              }}
              className="position-absolute pb-2"
            >
              {lang === "EN" ? "Room " : "Ruangan "}
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "LeelaUIb",
                }}
              >
                <div style={{color: "red"}}>Offline</div>
              </div>
            </div>
          </div>
        </Col>
      ))
    }
    </Row>

  }

  const renderNewPage = () =>{

    return [0,1,2,3].map((outObj, outKey)=>{
      let currentRowIndexes = []
      for(let i = 0; i<5; i++){
        currentRowIndexes.push(outObj * 5 + i)
      }
      return <Row>
        {
          currentRowIndexes.map((r,key)=>{
            return <Col
              style={{
                backgroundImage:
                  room === (r) ? 'url("Group 28.png")' : 'url("Group 25.png")',
                color: room === (r) ? "#EFECDB" : "white",
                pointerEvents: r >= metas.length && "none",
                backgroundSize: "100% 100%",
                height : "15vh",
                display : "flex",
                alignItems : 'center'
              }}
            >
              <div
                onClick={() => {setRoom(r), console.log(r)}}
                className="roomCard w-100 position-relative"
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    lineHeight: 1,
                    // top: "50%",
                    // left: "50%",
                    // transform: "translate(-50%, -50%)",
                    margin: 0,
                    paddingTop : 50,
                    paddingBottom : 50
                  }}
                >
                  {lang === "EN" ? "Room " : "Ruangan "}
                  {r + 1}
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "LeelaUIb",
                    }}
                  >
                    {metas[r] != null || r == 0? (
                      ` (${metas[r]}/50)`
                    ) : (
                      <div style={{color: "red"}}>Offline</div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          })
        }
      </Row>
    })

  }

  console.log("RE-RENDERING WITH ", metas)

  return (
    <div className="Container" style={{ display: "flex" }}>
      <ConfirmationModal
        show={isConfirmationModalShown}
        onClose={() => setConfirmationModalShown(false)}
        onConfirm={() => {
          localStorage.setItem("room", room.toString());
          navigate("/loading");
        }}
      />

      <Container
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Row style={{ flex: 1 }}>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="Room-Selection-text">
              {lang === "EN" ? "Room Selection" : "Pilih Ruangan"}
            </div>
          </Col>
        </Row>

        <Container>
          {renderNewPage()}
        </Container>

        <Row>
          <div style={{ marginBottom: "5vh", marginTop : "5vh"}}>
            <div className="d-flex buttons justify-content-center align-items-center">
              <div
                className="Cursor-pointer text-center position-relative d-flex mr-2"
                onClick={() => {
                  navigate("/customize-character");
                }}
              >
                <img
                  alt="select"
                  src={"/box-btn2.png"}
                  style={{ width: "100%", maxWidth: 240 }}
                  onDragStart={(e) => e.preventDefault()}
                  className="Cursor-pointer"
                />
                <div
                  style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "1em",
                    color: "white",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    letterSpacing: "0.3em",
                  }}
                  className="Cursor-pointer position-absolute pb-2"
                >
                  {lang === "EN" ? "Back" : "Kembali"}
                </div>
              </div>
              <div
                className="Cursor-pointer text-center position-relative d-flex"
                onClick={() => {

                  if(metas[room] >= 50){
                    return alert("This room is full")
                  }

                  setConfirmationModalShown(true)

                }}
              >
                <img
                  alt="select"
                  src={"/box-btn.png"}
                  style={{ width: "100%", maxWidth: 240 }}
                  onDragStart={(e) => e.preventDefault()}
                  className="Cursor-pointer"
                />
                <div
                  style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "1em",
                    color: "white",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    letterSpacing: "0.3em",
                  }}
                  className="Cursor-pointer position-absolute pb-2"
                >
                  {lang === "EN" ? "Select" : "Pilih"}
                </div>
              </div>
            </div>
          </div>
        </Row>
        {/*<Row>*/}
        {/*  <Col*/}
        {/*    className="d-flex justify-content-center buttons"*/}
        {/*    style={{ fontSize: "2vh" }}*/}
        {/*  >*/}
        {/*    <ReactPaginate*/}
        {/*      nextLabel="next >"*/}
        {/*      onPageChange={handlePageClick}*/}
        {/*      pageRangeDisplayed={6}*/}
        {/*      marginPagesDisplayed={2}*/}
        {/*      pageCount={pageCount}*/}
        {/*      previousLabel="< previous"*/}
        {/*      pageClassName="optionText"*/}
        {/*      pageLinkClassName="customPageLink"*/}
        {/*      previousClassName="optionText"*/}
        {/*      previousLinkClassName="customPageLink"*/}
        {/*      nextClassName="optionText"*/}
        {/*      nextLinkClassName="customPageLink"*/}
        {/*      breakLabel="..."*/}
        {/*      breakClassName="optionText"*/}
        {/*      breakLinkClassName="customPageLink"*/}
        {/*      containerClassName="pagination"*/}
        {/*      activeClassName="active"*/}
        {/*      renderOnZeroPageCount={null}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Container>
    </div>
  );
}
