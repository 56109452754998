import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function ConfirmationModal(props) {
  const { show, onClose, onConfirm } = props;
  const lang = localStorage.getItem("lang");

  const disableScrolling = () => {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  };

  const enableScrolling = () => {
    window.onscroll = function () {};
  };

  useEffect(() => {
    if (show) disableScrolling();
    else enableScrolling();
  }, [show]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "100%",
        minHeight: "100vh",
        zIndex: show ? 2 : -1,
        backgroundColor: "#00000059",
        opacity: show ? 1 : 0,
        transition: "zIndex .2s",
      }}
    >
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            background: 'url("box.png") no-repeat',
            maxWidth: "50vh",
            borderRadius: 15,
            paddingBottom: 20,
            backgroundRepeat: "no-repeat",
            minHeight: "20vh",
            textAlign: "center",
            padding: 20,
            paddingRight: 30,
            backgroundSize: "100% 100%",
          }}
        >
          <div style={{ fontSize: "2.7em", lineHeight: 1 }} className="mt-3">
            {lang === "EN" ? "Are you sure ?" : "Yakin dengan pilihanmu ?"}
          </div>

          <Row className="mt-4 m-auto">
            <Col>
              <div className="noButton Cursor-pointer" onClick={onClose}>
                <div
                  class="pb-2 Cursor-pointer d-flex align-items-center justify-content-center"
                  style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    // fontSize: "24px",
                    color: "white",
                    minHeight: "5vh",
                    letterSpacing: '0.3em'
                  }}
                >
                  &nbsp;{lang === "EN" ? "No" : "Tidak"}
                </div>
              </div>
            </Col>

            <Col>
              <div className="yesButton Cursor-pointer" onClick={onConfirm}>
                <div
                  class="pb-2 Cursor-pointer d-flex align-items-center justify-content-center"
                  style={{
                      fontStyle: "normal",
                      fontWeight: 700,
                      // fontSize: "24px",
                      color: "white",
                      display: "inline-block",
                      minHeight: "5vh",
                      letterSpacing: '0.3em'
                  }}
                >
                    &nbsp;{lang === "EN" ? "Yes" : "Yakin"}
                </div>
              </div>
              <div style={{ flex: 1 }} />
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
}
