import ApiRequest from '../utils/APIRequest';

export default class Users {
  static login = async (username) => {
    return await ApiRequest.set('v1/webuser/loginUsername', 'POST',{
      username
    });
  };

  static loginV2 = async (body) => {
    return await ApiRequest.set('v1/webuser/loginUsernamePassword', 'POST', body);
  };
}
