import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import WelcomePage from "./components/WelcomePage";
import LoginPage from "./components/LoginPage";
import LoadingPage from "./components/LoadingPage";
import CustomizeCharacterPage from "./components/CustomizeCharacterPage";
import RoomSelectionPage from "./components/RoomSelectionPage";

export default function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<LoginPage/>}/>
                <Route path="/loading" element={<LoadingPage/>}/>
                {/*<Route path="/login" element={<LoginPage/>}/>*/}
                <Route path="/customize-character" element={<CustomizeCharacterPage/>}/>
                <Route path="/room-selection" element={<RoomSelectionPage/>}/>
            </Routes>
        </HashRouter>
    );
}
