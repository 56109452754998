import '../App.css';
import {Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ConfirmationModal from "./reusable/ConfirmationModal";
import Users from "../models/Users"

export default function LoginPage() {

    let navigate = useNavigate()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [selectedLang, setSelectedLang] = useState('EN');
    const [error, setError] = useState("")

    const login = async() =>{
        try{
            /* let result = await Users.loginV2({username, password})
            console.log(result) */

            // localStorage.setItem('user', JSON.stringify(result))

            localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoZW50aWNhdGVkIjp0cnVlLCJ3ZWJfdXNlcl9pZCI6OSwidXNlcm5hbWUiOiJkZW1vIiwiaWF0IjoxNjk5MDAwOTQwLCJleHAiOjE2OTk2MDU3NDB9.9n-ePlFOavMQyJMLkggkD-1GxlL_5er3bTb7br0m1P4")
            localStorage.setItem("gender", "M")
            localStorage.setItem("username", "demo")

            // setLoading(false)

            localStorage.setItem("lang", selectedLang);
            setError(false)
            navigate("/customize-character")

        }catch (e) {
            console.log(e)
            if(e.error_message === "USERNAME_NOT_FOUND" || e.error_message === "PASSWORD_WRONG" || e.code === "PASSWORD_WRONG"){
                setError("* Username/password is wrong")
            }else{
                setError("* An Error Occured")
            }
        }

    }

    return (
        <div className="Container Vertical-center Flex-1-centered position-relative">
            <div className='position-absolute text-center' style={{top:'50%', left: '50%', transform: 'translate(-50%,-50%'}}>
                <div className='Welcome-text mt-4'>
                    {selectedLang === 'EN' ? 'WELCOME' : 'SELAMAT DATANG'}
                </div>

                <Container style={{
                    display : "flex",
                    alignItems : "center",
                    justifyContent :"center",
                    marginTop : 20
                }}
                >
                    <Row style={{
                        display : "flex",
                        alignItems : "center",
                        justifyContent :"center"
                    }}>
                        <Col
                          style={{
                              display : "flex",
                              alignItems : "center",
                              justifyContent :"center"
                          }}
                          md={4}>
                            <div className="Login-bg">
                                <div className="Container-flex-column d-flex align-items-center">
                                    <div className="d-flex align-items-center flex-column">
                                        {/* <div className="Login-text mb-2 mt-5" style={{color: '#754C29'}}>
                                            {selectedLang === 'EN' ? 'Click the Enter button to continue' : 'Klik tombol Masuk untuk melanjutkan'}
                                        </div> */}

                                        {/* <div className="Login-description-text mb-3">
                                            {selectedLang === 'EN' ? 'sign in to your account' : 'Masuk ke akun Anda'}
                                        </div> */}
                                    </div>

                                    <div className="d-flex align-items-center flex-column">
                                        {/* <InputGroup className="Input mb-3">
                                            <InputGroup.Text className={"Input-bg border-0"}>
                                                <img alt='user' src={'/icon-username.png'} style={{minHeight: '2vh'}}/>
                                            </InputGroup.Text>
                                            <Form.Control
                                              value={username}
                                              onChange={e => {
                                                  setUsername(e.target.value)
                                              }}
                                              className={"Input-bg border-0"} placeholder="Username"/>
                                        </InputGroup>

                                        <InputGroup className="Input mt-3">
                                            <InputGroup.Text className={"Input-bg border-0"}>
                                                <img alt='user' src={'/icon-password.png'}
                                                     style={{minHeight: '2vh', maxWidth: '3vh'}}/>
                                            </InputGroup.Text>
                                            <Form.Control
                                              type='password'
                                              value={password}
                                              onChange={e => {
                                                  setPassword(e.target.value)
                                              }}
                                              className={"Input-bg border-0"} placeholder="Password"/>
                                        </InputGroup>
                                        {error && <div className='error' style={{
                                            fontFamily: 'MyriadPro',
                                            textAlign: 'left',
                                            width: '80%',
                                            marginTop: 5,
                                            color: '#D72C2C'
                                        }}>
                                            {error}
                                        </div>} */}

                                        <div className='mt-5' style={{
                                            color: '#66472D',
                                            fontSize: '2em',
                                            fontFamily: 'MyriadPro',
                                            textAlign: 'left',
                                            width: 'fit-content',
                                            marginTop: 15
                                        }}>
                                            {selectedLang === 'EN' ? 'Choose your Language' : 'Pilih Bahasa'}
                                        </div>

                                        <div
                                          style={{display: 'flex', flexDirection: 'row', width: 'fit-content', marginTop: 5}}>
                                            <div
                                              onClick={() => setSelectedLang('EN')}
                                              style={{
                                                  textAlign: 'left',
                                                  fontSize: '1.5em',
                                                  fontFamily: 'sans-serif',
                                                  cursor: 'pointer'
                                              }}
                                              className='optionSelection d-flex align-items-center justify-content-center mr-3'>
                                                <img src={selectedLang === 'EN' ? 'active-select.png' : 'inactive-select.png'}
                                                     style={{marginRight: 5, marginTop: 2, minWidth: '3vh'}}/>
                                                ENGLISH
                                            </div>

                                            <div
                                              onClick={() => setSelectedLang('ID')}
                                              style={{
                                                  flex: 1,
                                                  textAlign: 'left',
                                                  fontSize: '1.5em',
                                                  fontFamily: 'sans-serif',
                                                  cursor: 'pointer'
                                              }}
                                              className='optionSelection d-flex align-items-center justify-content-center'>
                                                <img src={selectedLang === 'ID' ? 'active-select.png' : 'inactive-select.png'}
                                                     style={{marginRight: 5, marginTop: 2, minWidth: '3vh'}}/>
                                                INDONESIAN
                                            </div>
                                        </div>

                                        <div style={{
                                            fontFamily: 'leelawadee-ui-normal',
                                            textAlign: 'left',
                                            width: '80%',
                                            marginTop: 5,
                                            opacity: 0,
                                            pointerEvents: 'none'
                                        }}>
                                            *Pilih Bahasa
                                        </div>

                                        {/*<InputGroup className="Input">*/}
                                        {/*    <InputGroup.Text className={"Input-bg border-0"}>*/}
                                        {/*        <img src={'/icon-password.png'} className="Icon"/>*/}
                                        {/*    </InputGroup.Text>*/}
                                        {/*    <Form.Control className={"Input-bg border-0"} placeholder="Password" type={'password'}/>*/}
                                        {/*</InputGroup>*/}
                                    </div>


                                    <div className="Flex-0-9-centered mb-3"
                                         style={{fontFamily: 'sans-serif', position: 'relative'}} onClick={() => {
                                        login()
                                    }}>
                                        <img
                                          alt='login'
                                          src={'/login-btn.png'}
                                          style={{width: '100%', maxWidth: 300}}
                                          onDragStart={(e) => e.preventDefault()} className="Login-btn Cursor-pointer"/>
                                        <div className="position-absolute pb-2 signIn Cursor-pointer" style={{
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontStyle: 'normal',
                                            fontWeight: 700,
                                            fontSize: '1.5em',
                                            lineHeight: '1em',
                                            color: 'white',
                                            letterSpacing: '0.3em'
                                        }}>{selectedLang === 'EN' ? 'Enter' : 'Masuk'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>

                {/* <div className="Color-black mb-3 mt-2"
                     style={{fontFamily: 'Myriad Pro',
                         fontStyle: 'normal',
                         fontWeight: 400,
                         lineHeight: '1em',
                         color: 'white'}}>
                    <small>
                        You can use demo as username and demo123 for password
                    </small>
                </div> */}

            </div>
        </div>
    );
}
