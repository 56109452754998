import '../App.css';
import {Container, Form, InputGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Unity, {UnityContext} from "react-unity-webgl";

import LoadingIcon from "../assets/images/Loading.png"
import SpeedWitch from "../assets/images/speedwitch.png"

const unityContext = new UnityContext({
    loaderUrl: "build/Build.loader.js",
    dataUrl: "build/Build.data",
    // dataUrl: "https://telin.s3.ap-southeast-1.amazonaws.com/build19/Build.data.unityweb",
    frameworkUrl: "build/Build.framework.js",
    codeUrl: "build/Build.wasm",
});

unityContext.on("error", function (message) {
    console.log("MESSAGE", typeof message, message)
    if(message.includes('wasm') && message.includes('failed')){
        return
    }
    if(message.includes('ArrayBuffer')){
        return
    }
    alert("An error occured. Please try refreshing the page  or use another browser.")
});

export default function LoginPage() {

    const [progression, setProgression] = useState(0)

    let navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem("token") || !localStorage.getItem("character") || !localStorage.getItem("gender") || !localStorage.getItem("username") || !localStorage.getItem("lang") || !localStorage.getItem("room")) {
            navigate("/")
        } else {
            console.log({
                room: localStorage.getItem("room"),
                character: localStorage.getItem("character"),
                token: localStorage.getItem("token"),
                username: localStorage.getItem("username"),
                lang: localStorage.getItem("lang")
            })
            unityContext.on("progress", function (progression) {
                setProgression(progression);
            });
        }
    }, [])

    return (
        <Container fluid={true}
            style={{
                overflowY : "hidden",
                overflowX : "hidden",
                padding : 0
            }}
            className="Container">

            {
                progression < 1 ?
                    <div style={{
                        width : "100%",
                        height : "100vh",
                        display : "flex",
                        alignItems : "center",
                        justifyContent : "center",
                        flexDirection : "column",
                    }}>
                        <img
                            style={{objectFit : "contain"}}
                            src={SpeedWitch}/>
                        <div
                            className='Welcome-text-bolder'>
                            Loading...
                        </div>
                        <br/>
                        <div className='Welcome-text'>
                            {Math.floor(progression * 100)}%
                        </div>
                    </div>
                    :
                    <></>
            }

            <Unity
                style={{
                    height: progression >= 1 ? "calc(100vh)" : 0,
                    width: "100vw",
                }}
                unityContext={unityContext}/>

        </Container>
    );
}
