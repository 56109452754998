import "../App.css";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import m1 from "../assets/images/characters/M1A.png";
import m2 from "../assets/images/characters/M2A.png";
import f1 from "../assets/images/characters/F1A.png";
import f2 from "../assets/images/characters/F2A.png";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./reusable/ConfirmationModal";
import "../assets/style.scss";
const maleSelection = [m1, m2];
const femaleSelection = [f1, f2];

export default function CustomizeCharacterPage() {
  const NO_OF_VARIATION = 5;
  let navigate = useNavigate();

  const lang = localStorage.getItem("lang");
  let [username, setUsername] = useState("");
  let [gender, setGender] = useState("M");
  const [variation, setVariation] = useState(1);
  const [color, setColor] = useState("A");
  const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [characterName, setCharacterName] = useState("");
  const [error, setError] = useState(false);

  let [selectedIndex, setSelectedIndex] = useState(0);

  const getAvatarSelection = () => {
    let avatarSelection = [];
    if (gender === "M") {
      avatarSelection = maleSelection;
    } else {
      avatarSelection = femaleSelection;
    }
    return avatarSelection;
  };

  const changeColor = () => {
    setColor(color === "A" ? "B" : "A");
  };

  const nextModel = () => {
    if (variation === NO_OF_VARIATION) {
      setVariation(1);
    } else {
      setVariation(variation + 1);
    }

    let selection = getAvatarSelection();
    if (selectedIndex + 1 > selection.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const prevModel = () => {
    if (variation - 1 === 0) {
      setVariation(NO_OF_VARIATION);
    } else {
      setVariation(variation - 1);
    }

    let selection = getAvatarSelection();
    if (selectedIndex - 1 < 0) {
      setSelectedIndex(selection.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const nextGender = () => {
    if (gender === "M") {
      setGender("F");
    } else {
      setGender("M");
    }
  };

  const handleConfirmCharacter = () => {
    if (characterName !== "" && characterName !== " ") {
      setConfirmationModalShown(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("character")) {
      setVariation(parseInt(localStorage.getItem("character").charAt(1)));
      setColor(localStorage.getItem("character").charAt(2));
    }

    function handleResize() {
      console.log(window.innerWidth);

      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    if (
      localStorage.getItem("token") &&
      localStorage.getItem("gender") &&
      localStorage.getItem("username") &&
      localStorage.getItem("lang")
    ) {
      setUsername(localStorage.getItem("username"));
      setGender(localStorage.getItem("gender"));
    } else {
      navigate("/");
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(`${gender}${variation}${color}.png`);
  }, [variation, color, gender]);

  return (
    <div className="Container Vertical-center">
      <ConfirmationModal
        show={isConfirmationModalShown}
        onClose={() => setConfirmationModalShown(false)}
        onConfirm={() => {
          localStorage.setItem("character", gender + variation + color);
          localStorage.setItem("username", characterName);
          navigate("/room-selection");
        }}
      />

      <Container>
        <Row>
          <Col className="Flex-1-centered Align-items-flex-start mr-5 pr-5">
            <Row>
              <Col
                style={{ textAlign: "center", fontSize: "3rem" }}
                className="helloText mb-5 mt-3"
              >
                {lang === "EN" ? "Hello" : "Halo"} !
              </Col>
            </Row>
            <Row
              onDragStart={(e) => e.preventDefault()}
              className="w-100 optionText mb-5"
            >
              <Col className="labelText my-auto">
                <b>{lang === "EN" ? "Character Name" : "Nama Karakter"}</b>
                {error && (
                  <div
                    className="error"
                    style={{
                      fontFamily: "MyriadPro",
                      textAlign: "left",
                      width: "80%",
                      marginTop: 5,
                      color: "white",
                      fontSize: '0.7em'
                    }}
                  >
                    * Please fill character name
                  </div>
            )}
              </Col>
              <Col className="d-flex p-0" xs={3} md={7}>
                <InputGroup className="w-100 pl-1 text-center">
                  <InputGroup.Text className={"Input-bg optionText mr-2 my-1"}>
                    <img
                      alt="user"
                      src={"/icon-username.png"}
                      style={{ minHeight: "2vh" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    value={characterName}
                    onChange={(e) => {
                      setCharacterName(e.target.value);
                      setError(!!!e.target.value)
                    }}
                    style={{
                      background : "#efe3da",
                      color : "#8f5352"
                    }}
                    className={"Input-bg border-0 characterName text-center"}
                    placeholder={lang === "EN" ? "Character Name" : "Nama Karakter"}
                  />
                </InputGroup>
              </Col>
            </Row>
            
            <Row
              onDragStart={(e) => e.preventDefault()}
              className="optionText mb-5 w-100"
            >
              <Col className="labelText my-auto">
                <b>Gender</b>
              </Col>
              <Col className="d-flex labelTextInnerText p-0" xs={3} md={7}>
                <img
                  alt="arrow-left"
                  src={"left-btn.png"}
                  onClick={() => {
                    nextGender();
                  }}
                  className="Width-100 Cursor-pointer"
                />
                <div className="text-nowrap m-auto">
                  {lang === "EN"
                    ? gender === "M"
                      ? "Male "
                      : "Female"
                    : gender === "M"
                    ? "Pria "
                    : "Wanita"}
                </div>
                <img
                  alt="arrow-right"
                  src={"right-btn.png"}
                  onClick={() => {
                    nextGender();
                  }}
                  className="Width-100 Cursor-pointer"
                />
              </Col>
            </Row>
            <Row
              onDragStart={(e) => e.preventDefault()}
              className="optionText mb-5 w-100"
            >
              <Col className="labelText my-auto">
                <b>{lang === "EN" ? "Color" : "Warna"}</b>
              </Col>
              <Col className="d-flex labelTextInnerText p-0" xs={3} md={7}>
                <img
                  alt="arrow-left"
                  src={"left-btn.png"}
                  onClick={() => {
                    changeColor();
                  }}
                  className="Width-100 Cursor-pointer"
                />
                <div className="text-nowrap m-auto">
                  {lang === "EN" ? "Color " : "Warna "} {color}
                </div>
                <img
                  alt="arrow-right"
                  src={"right-btn.png"}
                  onClick={() => {
                    changeColor();
                  }}
                  className="Width-100 Cursor-pointer"
                />
              </Col>
            </Row>
            <Row
              onDragStart={(e) => e.preventDefault()}
              className="optionText mb-5 w-100"
            >
              <Col className="labelText my-auto">
                <b>{lang === "EN" ? "Pattern" : "Pola"}</b>
              </Col>
              <Col className="d-flex labelTextInnerText p-0" xs={3} md={7}>
                <img
                  alt="arrow-left"
                  src={"left-btn.png"}
                  onClick={() => {
                    prevModel();
                  }}
                  className="Width-100 Cursor-pointer"
                />
                <div className="text-nowrap m-auto">
                  {lang === "EN" ? "Pattern " : "Pola "} {variation}
                </div>
                <img
                  alt="arrow-right"
                  src={"right-btn.png"}
                  onClick={() => {
                    nextModel();
                  }}
                  className="Width-100 Cursor-pointer"
                />
              </Col>
            </Row>
          </Col>

          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            xs={3}
            md={5}
            className="pl-5 ml-5"
          >
            <div onDragStart={(e) => e.preventDefault()}>
              <img
                src={require(`../assets/images/characters/${gender}${variation}${color}.png`)}
                alt="user"
                style={{ width: 300 }}
              />
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: 30 }}>
          <div className="d-flex w-50 m-auto buttons">
            <div
              className="Cursor-pointer text-center position-relative d-flex"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                alt="select"
                src={"/box-btn2.png"}
                style={{ width: "100%", maxWidth: 240 }}
                onDragStart={(e) => e.preventDefault()}
                className="Cursor-pointer"
              />
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "24px",
                  color: "white",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  letterSpacing: "0.3em",
                }}
                className="Cursor-pointer position-absolute pb-2"
              >
                {lang === "EN" ? "Back" : "Kembali"}
              </div>
            </div>
            <div
              className="Cursor-pointer text-center position-relative d-flex"
              onClick={() => handleConfirmCharacter()}
            >
              <img
                alt="select"
                src={"/box-btn.png"}
                style={{ width: "100%", maxWidth: 240 }}
                onDragStart={(e) => e.preventDefault()}
                className="Cursor-pointer"
              />
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "24px",
                  color: "white",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  letterSpacing: "0.3em",
                }}
                className="Cursor-pointer position-absolute pb-2"
              >
                {lang === "EN" ? "Select" : "Pilih"}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
